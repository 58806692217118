/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.project-activity-page {
  min-height: 600px;
  height: calc(100vh - 250px);
}

.project-activity-page-side-outer {
  position: relative;
  width: 400px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #fff !important;
}

.project-activity-layout-page-main {
  flex-grow: 1;
  min-width: 640px;
  padding-left: 20px;
  display: flex;
}

.project-activity-layout-page-main-inner {
  min-width: 640px;
  max-width: 880px;
  margin-bottom: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.project-activity-versions-list {
  max-width: 400px;
  box-sizing: border-box;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 16px 16px 12px;
}

.project-activity-day {
  margin-top: 8px;
  margin-bottom: 24px;
}

.project-activity-day:last-child {
  margin-bottom: 10px;
}

.project-activity-analysis {
  position: relative;
  min-height: 20px;
  padding: 16px;
  cursor: pointer;
}

.project-activity-analysis.selected {
  cursor: default;
  background-color: #ecf6fe;
}

.project-activity-analysis:focus {
  outline: none;
}

.project-activity-analysis:hover {
  background-color: #ecf6fe;
}

.project-activity-analysis + .project-activity-analysis {
  border-top: none;
}

.project-activity-analysis-actions {
  height: 20px;
}

.project-activity-time {
  height: 20px;
  line-height: 20px;
}

.project-activity-event {
  line-height: 20px;
  text-indent: -20px;
  padding-left: 20px;
}

.project-activity-event * {
  text-indent: 0;
}

.project-activity-event + .project-activity-event {
  margin-top: 8px;
}

.project-activity-event-inner-more-link {
  line-height: 16px;
}

.project-activity-event-icon.VERSION {
  color: #4b9fd5;
}

.project-activity-event-icon.QUALITY_GATE {
  color: #9139d4;
}

.project-activity-event-icon.QUALITY_PROFILE {
  color: #cccccc;
}

.project-activity-event-icon.DEFINITION_CHANGE {
  color: #33a759;
}

.project-activity-event-icon.OTHER {
  color: #442d1b;
}

.project-activity-version-badge {
  position: -webkit-sticky;
  position: sticky;
  top: -24px;
  left: 12px;
  right: 16px;
  margin-left: -12px;
  background-color: white;
  padding-top: 24px;
  padding-bottom: 8px;
  z-index: 1;
}

.project-activity-version-badge.first {
  top: 0;
  padding-top: 0;
}

.project-activity-version-badge .analysis-version {
  max-width: 385px;
  border-radius: 0 2px 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Select .project-activity-event-icon,
.activity-graph-tooltip-line .project-activity-event-icon {
  margin-top: 1px;
}

.baseline-marker {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.baseline-marker > .wedge {
  border: 10px solid transparent;
  border-left-color: #f1e8cb;
}

.baseline-marker > hr {
  border: none;
  margin: 0 0 0 -11px;
  background-color: #f1e8cb;
  height: 2px;
  flex: 1 0 auto;
}

.baseline-marker > .label {
  background-color: #fbf3d5;
  border: 1px solid #f1e8cb;
  padding: 2px 8px;
  font-size: 10px;
}
