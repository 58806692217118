/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

.coding-rules-extended-view .coding-rules-list {
  display: none;
}

.coding-rules-extended-view .coding-rules-details {
  display: block;
}

.coding-rules-similar-tag-divider {
  margin-top: 6px;
  border-top: 1px solid #e6e6e6;
}

.coding-rules-filter-title {
  padding: 8px;
  font-size: 12px;
  color: #666666;
  white-space: nowrap;
  line-height: unset;
}

/*
 * Detail
 */

.coding-rules-detail-title {
  position: relative;
  display: inline-block;
  margin: 16px 0;
  font-size: 22px;
  font-weight: 500;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
}

.coding-rules-detail-quality-profile-parameter {
  display: block;
  height: 100%;
  padding-top: 0;
  padding-right: 10px;
}

.coding-rules-detail-quality-profile-parameter .key,
.coding-rules-detail-quality-profile-parameter .value,
.coding-rules-detail-quality-profile-parameter .sep {
  display: inline;
  vertical-align: top;
}

.coding-rules-detail-quality-profile-parameter .value {
  display: inline-block;
  vertical-align: top;
  line-height: 23px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
}

.coding-rules-detail-quality-profile-parameter + .coding-rules-detail-quality-profile-parameter {
  margin-top: 0;
}

.coding-rules-detail-header {
  font-size: 18px;
}

.coding-rules-detail-properties {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}

.coding-rules-detail-properties + .coding-rules-detail-properties {
  margin-top: -10px;
}

.coding-rules-detail-property {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}

.coding-rules-detail-tag + .coding-rules-detail-tag {
  margin-left: 10px;
}

.coding-rules-detail-tags-change {
  cursor: pointer;
}

.coding-rules-detail-tags-change:hover span {
  text-decoration: underline;
}

.coding-rules-detail-tag-edit {
  line-height: 1;
}

.coding-rules-details-tag-edit-cancel {
  vertical-align: middle;
}

.coding-rules-detail-description {
  margin: 20px 0;
}

.coding-rules-detail-extend-description-form {
  margin: 10px 0;
}

.coding-rules-detail-parameters {
  width: 100%;
  margin: 10px 0 20px;
}

.coding-rules-detail-parameter-name {
  width: 1px;
  vertical-align: top;
  padding: 5px 10px 5px 0;
  font-weight: bold;
}

.coding-rules-detail-parameter-description {
  vertical-align: top;
  padding: 5px 5px;
}

.coding-rules-detail-parameter-value {
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  word-break: break-all;
}

.coding-rules-detail-quality-profiles,
.coding-rules-detail-list {
  width: 100%;
  line-height: 22px;
}

.coding-rules-detail-quality-profiles td,
.coding-rules-detail-list td {
  border-top: 1px solid #e6e6e6;
}

.coding-rules-detail-quality-profiles tr:first-child td,
.coding-rules-detail-list tr:first-child td {
  border-top: none;
}

.coding-rules-detail-quality-profile-name,
.coding-rules-detail-list-name {
  vertical-align: top;
  width: 1px;
  padding: 8px 5px 8px 0;
  font-weight: 600;
  white-space: nowrap;
}

.coding-rules-detail-quality-profile-severity,
.coding-rules-detail-list-severity {
  vertical-align: top;
  width: 1px;
  padding: 8px 5px;
  white-space: nowrap;
}

.coding-rules-detail-quality-profile-severity i {
  margin-top: 2px;
}

.coding-rules-detail-quality-profile-parameters,
.coding-rules-detail-list-parameters {
  vertical-align: top;
  padding: 8px 5px;
}

.coding-rules-detail-quality-profile-actions,
.coding-rules-detail-list-actions {
  vertical-align: top;
  width: 1px;
  padding: 8px 0 8px 5px;
  text-align: right;
  white-space: nowrap;
}

.coding-rules-detail-quality-profile-inheritance {
  font-size: 12px;
  font-weight: normal;
}

.coding-rules-detail-quality-profiles-activation {
  margin-top: -3px;
  margin-left: 10px;
}

.coding-rules-most-violated-projects td {
  border-top-color: transparent;
}

.coding-rules-most-violated-projects tr:first-child + tr td {
  border-top-color: #e6e6e6;
}

.coding-rules-most-violated-projects .coding-rules-detail-list-name {
  font-weight: 400;
}

.coding-rule {
  padding: 8px 10px 8px 8px;
  border: 1px solid transparent;
  background-color: #fff;
}

.coding-rule.selected {
  border-color: #4b9fd5 !important;
}

.coding-rule + .coding-rule {
  border-top-color: #e6e6e6;
}

.coding-rule.selected + .coding-rule {
  border-top-color: transparent;
}

.coding-rule-table {
  width: 100%;
}

.coding-rule-table td {
  vertical-align: middle;
}

.coding-rule-table + .coding-rule-table {
  margin-top: 5px;
}

.coding-rule-table-meta-cell {
  width: 1px;
  white-space: nowrap;
}

@media (max-width: 1320px) {
  .coding-rule-table .tags-list span {
    max-width: 100px;
  }
}
.coding-rule-title {
  line-height: 20px;
  font-size: 13px;
}

.coding-rule-meta {
  padding: 2px;
  padding-left: 30px;
  font-size: 12px;
}

.coding-rule-activation {
  width: 40px;
  line-height: 19.5px;
}

.coding-rule-activation-actions {
  padding-left: 20px;
}

.notice-dot {
  height: 8px;
  width: 8px;
  background-color: #4b9fd5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
}
