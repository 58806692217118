/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.quality-profiles-table {
  padding-top: 7px;
}

.quality-profiles-table-inheritance {
  width: 280px;
}

.quality-profiles-table-projects,
.quality-profiles-table-rules,
.quality-profiles-table-date {
  min-width: 80px;
}

.quality-profiles-list-header {
  line-height: 24px;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-bottom: 1px solid #e6e6e6;
}

.quality-profile-grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.quality-profile-grid-left {
  width: 340px;
  flex-shrink: 0;
}

.quality-profile-grid-right {
  flex-grow: 1;
  margin-left: 20px;
}

.quality-profile-rules-distribution {
  margin-bottom: 15px;
  padding: 7px 20px 0;
}

.quality-profile-rules-deprecated {
  margin-top: 20px;
  padding: 15px 20px;
  background-color: #f2dede;
}

.quality-profile-rules-sonarway-missing {
  margin-top: 20px;
  padding: 15px 20px;
  background-color: #fcf8e3;
}

.quality-profile-not-found {
  padding-top: 100px;
  text-align: center;
}

.quality-profiles-evolution {
  padding-top: 55px;
}

.quality-profiles-evolution-deprecated {
  border-color: #f4b1b0;
  background-color: #f2dede;
}

.quality-profiles-evolution-stagnant {
  border-color: #faebcc;
  background-color: #fcf8e3;
}

.quality-profiles-evolution-deprecated h2,
.quality-profiles-evolution-stagnant h2,
.quality-profiles-evolution-rules h2 {
  padding: 0;
}

.quality-profile-changelog-rule-cell {
  line-height: 1.5;
}

.quality-profile-changelog-parameter {
  max-width: 270px;
  word-break: break-word;
}

.quality-profile-compare-right-table:not(.has-first-column) td,
.quality-profile-compare-right-table:not(.has-first-column) th {
  /* Aligns the first column with the second one (50%) and add usual cell padding */
  padding-left: calc(50% + 10px);
}

#create-profile-form .radio-card {
  width: 244px;
  background-color: #F3F3F3;
  border: 1px solid #CCCCCC;
  margin-right: 0;
}

#create-profile-form .radio-card.selected {
  background-color: #ECF6FE;
  border: 1px solid #0271B9;
}

#create-profile-form .radio-card:hover:not(.selected) {
  border: 1px solid #0271B9;
}

#create-profile-form fieldset > div {
  justify-content: space-between;
}

#create-profile-form .radio-card-header {
  justify-content: space-around;
}

#create-profile-form .radio-card-body {
  justify-content: flex-start;
}
